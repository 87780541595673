<template>
  <div class="container">
    <busy :visible="loading" />
    <div v-if="!loading" class="row">
      <div class="col-md-8 mx-auto">
        <div class="card">
          <div class="card-body">
            <order-payment :order="order" @success="onSuccess"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderPayment from "../payment-processing/OrderPayment";
export default {
  name: "PractitionerOrderCardPayment",
  data() {
    return {
      loading: true,
      order: null
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  methods: {
    fetchOrder() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/orders/api/" + this.$route.params.id)
          .then(({ data }) => {
            this.order = data;
            this.loading = false;
          });
    },
    onSuccess() {
      const vm = this;
      setTimeout(function() {
        vm.$router.push('/orders/' + vm.order.id)
      }, 3000);
    }
  },
  created() {
    this.fetchOrder()
  },
  components: {
    OrderPayment,
  }
}
</script>

<style scoped>

</style>